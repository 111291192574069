.logo {
  float: left;
  width: 36px;
  height: 36px;
  margin: 0px 24px 16px 0px;
}

.ant-layout-header {
  background-color: #ffffff !important;
}

.loading {
  margin: 20px 0;
  margin-bottom: 20px;
  height: auto;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

#root, body, html {
  height: 100%;
}

.ant-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
}

.ant-layout-content {
  flex: 1 0 auto;
}

.ant-layout-footer {
  flex: 0 0 auto
}